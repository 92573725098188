{{#ifEquals products.length 0}}
{{else}}
    {{#if (contains @root.hiddenCategories id)}}
    {{else}}
        <li class="menuItem-list-child
            {{#if children}}
                {{#if (contains @root.hiddenCategoryParents id)}}
                {{else}} toggle js-tog
                {{/if}}
            {{/if}}" id="{{ id }}">
            {{#if href}}
                {{#if children}}
                    {{#if (contains @root.hiddenCategoryParents id)}}
                    {{else}}
                        <span class="toggle-trigger js-togTrig"></span>
                    {{/if}}
                {{/if}}
                <a href="{{href}}" class="menuItem-list-child-link">{{name}}</a>
            {{else}}
                {{#if children}}
                    {{#if (contains @root.hiddenCategoryParents id)}}
                    {{else}}
                        <span class="toggle-trigger js-togTrig"></span>
                    {{/if}}
                {{/if}}
                <div class="menuItem-list-child-lbl">{{name}}</div>
            {{/if}}
            {{#if children}}
                {{#if (contains @root.hiddenCategoryParents id)}}
                    <ul class="menuItem-list-child-list">
                {{else}}
                    <ul class="menuItem-list-child-list {{#if children}}toggle-target{{/if}}">
                {{/if}}
                {{#each children}}
                    {{#if (contains @root.hiddenCategories id)}}
                    {{else}}
                        <li class="menuItem-list-child-list-item" id="{{ id }}">
                            {{#if href}}
                                <a href="{{href}}" class="menuItem-list-child-list-item-link">{{name}}</a>
                            {{else}}
                                <div class="menuItem-list-child-list-item-lbl">{{name}}</div>
                            {{/if}}
                        </li>
                    {{/if}}
                {{/each}}
                </ul>
            {{else}}
                {{#if (contains @root.hiddenCategories id)}}
                {{else}}
                    <ul class="menuItem-list-child-list {{#if products}}toggle-target{{/if}}">
                        {{#each products}}
                            <li class="menuItem-list-child-list-item" id="{{ id }}">
                                {{#if href}}
                                    <a href="{{href}}" class="menuItem-list-child-list-item-link">{{name}}</a>
                                {{else}}
                                    <div class="menuItem-list-child-list-item-lbl">{{name}}</div>
                                {{/if}}
                            </li>
                        {{/each}}
                    </ul>
                {{/if}}
            {{/if}}
        </li>
    {{/if}}
{{/ifEquals}}