<div class="styleWidget js--element-sWidget">
    <div class="styleWidget-trigger js--action-toggleStyleWidget"></div>
    <div class="styleWidget-main">
        <div class="styleWidget-main-hd">
            <div>Theme Settings</div>
            <div>
                <label class="styleWidget-main-hd-peristLabel" for="persistToggle">Persist Settings:</label>
                <input class="styleWidget-main-hd-persistToggle js--element-persistToggle" type="checkbox" {{#if savedSettingsPersist }}checked {{/if}}name="persistToggle" id="persistToggle">
            </div>
        </div>
        <ul class="styleWidget-main-current">
            <li>Set Theme Style: <span>{{ setThemeStyleName }}</span></li>
            <li>Set Brand Color: <span>{{ setBrandColor }}</span></li>
            <li>Set Logo File: <span>{{ setLogoName }}</span></li>
        </ul>
        <div class="styleWidget-main-setting">
            <div class="styleWidget-main-setting-color">
                <label for="brandColor">Brand Color:</label>
                <input class="js--element-brandColorPicker" type="color" name="brandColor" value="{{ setBrandColor }}" />
            </div>
            <div class="styleWidget-main-setting-logo">
                <label for="globalLogo">Upload Logo:</label>
                <input class="js--element-logoFileInput" type="file" name="globalLogo" />
            </div>
            <div class="styleWidget-main-setting-font">
                <label for="fontStyle">Font Style:</label>
                <select class="js--element-fontStyleSelect" name="fontStyle">
                    {{#each fontsList}}
                        <option {{#ifEquals this.value ../setFontStyle}}selected {{/ifEquals}}value="{{this.value}}">{{name}}</option>
                    {{/each}}
                </select>
            </div>
            <div class="styleWidget-main-setting-headings">
                <label for="hdgSize">Heading Size:</label>
                <select class="js--element-headingSizeSelect" name="hdgSize">
                    <option {{#ifEquals 'large' setHeadingSize}}selected {{/ifEquals}}value="large">Large (default)</option>
                    <option {{#ifEquals 'medium' setHeadingSize}}selected {{/ifEquals}}value="medium">Medium</option>
                    <option {{#ifEquals 'small' setHeadingSize}}selected {{/ifEquals}}value="small">Small</option>
                </select>
            </div>
            <div class="styleWidget-main-setting-headings">
                <label for="hdgStyle">Heading Style:</label>
                <select class="js--element-hdgStyleSelect" name="hdgStyle">
                    <option {{#ifEquals 'uppercase' setHeadingStyle}}selected {{/ifEquals}}value="uppercase">All Caps (default)</option>
                    <option {{#ifEquals 'normal' setHeadingStyle}}selected {{/ifEquals}}value="normal">Normal</option>
                </select>
            </div>
        </div>
        <div class="styleWidget-main-styles">
            <div class="styleWidget-main-styles-hd">Theme Style:</div>
            <ul class="styleWidget-main-styles-options js--element-themeStylePicker">
                <li class="{{#ifEquals setThemeStyleName "Light"}}isCurrent{{/ifEquals}}" data-theme-style-name="Light">Light</li>
                <li class="{{#ifEquals setThemeStyleName "Dark"}}isCurrent{{/ifEquals}}" data-theme-style-name="Dark">Dark</li>
                <li class="{{#ifEquals setThemeStyleName "Brand"}}isCurrent{{/ifEquals}}" data-theme-style-name="Brand">Brand</li>
                <li class="{{#ifEquals setThemeStyleName "Brand Contrast"}}isCurrent{{/ifEquals}}" data-theme-style-name="Brand Contrast">Brand Contrast</li>
            </ul>
        </div>
        <div class="styleWidget-main-actions">
            <button class="js--action-resetDefaults">Reset to defaults</button>
            <button class="js--action-toggleStyleWidget">Cancel</button>
            <button class="styleWidget-main-actions-save js--action-saveStyles">Save Settings</button>
        </div>
    </div>
</div>