import styleWidgetHtml from "./styleWidget.hbs";
import "./styleWidget.css";

(function () {
    const params = new URLSearchParams(document.location.search);

    if (sessionStorage.getItem('savedSettingsPersist')) {
        const url = new URL(document.location);
        
        params.append('themeSettings', true);
        url.searchParams.set('themeSettings', true);
        history.pushState({}, '', url);
    }
    
    if (!params.get('themeSettings')) {
        return;
    }
    
    const styleWidgetEle = document.createElement('div');
    let customFontLink = document.createElement('link');
    let fontStyleEle = document.createElement('style');
    const themeStyles = {
        light: {
            '--tc-surface': 'var(--c-WHITE)',
            '--tc-surfaceAccent': 'var(--c-WHITE)',
            '--tc-surfaceSecondary': 'var(--c-NUETRAL-2)',
            '--tc-border': 'var(--c-NUETRAL-4)',
            '--tc-borderAccent': 'var(--c-NUETRAL-4)',
            '--tc-text': 'var(--c-NUETRAL-9)',
            '--tc-textAccent': 'var(--c-NUETRAL-9)',
            '--tc-link': 'var(--c-PRIMARY)',
            '--tc-linkFocus': 'var(--c-NUETRAL-9)',
            '--tc-linkAccent': 'var(--c-PRIMARY)',
            '--tc-linkAccentFocus': 'var(--c-NUETRAL-9)',
            '--tc-buttonSurface': 'var(--c-PRIMARY)',
            '--tc-buttonBorder': 'var(--c-PRIMARY)',
            '--tc-buttonText': 'var(--c-WHITE)',
            '--tc-buttonSurfaceAccent': 'var(--c-PRIMARY)',
            '--tc-buttonSurfaceAccentBorder': 'var(--c-PRIMARY)',
            '--tc-buttonSurfaceAccentText': 'var(--c-WHITE)',
            '--tc-bannerSurface': 'var(--c-PRIMARY)',
            '--tc-bannerText': 'var(--c-WHITE)',
            '--tc-headerSurface': 'var(--c-WHITE)',
            '--tc-headerBorderTop': 'var(--c-PRIMARY)',
            '--tc-headerBorderBottom': 'var(--c-NUETRAL-4)',
            '--tc-headerLogo': 'var(--c-PRIMARY)',
            '--tc-headerText': 'var(--c-NUETRAL-9)',
            '--tc-headerLink': 'var(--c-NUETRAL-9)',
            '--tc-headerLinkHover': 'var(--c-PRIMARY); /* NEW *',
            '--tc-headerInput': 'var(--c-NUETRAL-9)',
            '--tc-headerIcon': 'var(--c-NUETRAL-9)',
            '--tc-footerSurface': 'var(--c-NUETRAL-2)',
            '--tc-footerLogo': 'var(--c-PRIMARY)',
            '--tc-footerLogoBrightness': '',
            '--tc-footerText': 'var(--c-NUETRAL-9)',
            '--tc-footerTextSecondary': 'var(--c-NUETRAL-6)'
        },
        dark: {
            '--tc-surface': 'var(--c-WHITE)',
            '--tc-surfaceAccent': 'var(--c-NUETRAL-8)',
            '--tc-surfaceSecondary': 'var(--c-NUETRAL-8)',
            '--tc-border': 'var(--c-NUETRAL-4)',
            '--tc-borderAccent': 'var(--c-NUETRAL-9)',
            '--tc-text': 'var(--c-NUETRAL-9)',
            '--tc-textAccent': 'var(--c-WHITE)',
            '--tc-link': 'var(--c-PRIMARY)',
            '--tc-linkFocus': 'var(--c-NUETRAL-9)',
            '--tc-linkAccent': 'var(--c-PRIMARY)',
            '--tc-linkAccentFocus': 'var(--c-NUETRAL-9)',
            '--tc-buttonSurface': 'var(--c-PRIMARY)',
            '--tc-buttonBorder': 'var(--c-PRIMARY)',
            '--tc-buttonText': 'var(--c-WHITE)',
            '--tc-buttonSurfaceAccent': 'var(--c-NUETRAL-8)',
            '--tc-buttonSurfaceAccentBorder': 'var(--c-WHITE)',
            '--tc-buttonSurfaceAccentText': 'var(--c-WHITE)',
            '--tc-bannerSurface': 'var(--c-BLACK)',
            '--tc-bannerText': 'var(--c-WHITE)',
            '--tc-headerSurface': 'var(--c-NUETRAL-9)',
            '--tc-headerBorderTop': 'var(--c-BLACK)',
            '--tc-headerBorderBottom': 'var(--c-NUETRAL-9)',
            '--tc-headerLogo': 'var(--c-WHITE)',
            '--tc-headerText': 'var(--c-WHITE)',
            '--tc-headerLink': 'var(--c-WHITE)',
            '--tc-headerLinkHover': 'var(--c-PRIMARY)',
            '--tc-headerInput': 'var(--c-WHITE)',
            '--tc-headerIcon': 'var(--c-WHITE)',
            '--tc-footerSurface': 'var(--c-NUETRAL-9)',
            '--tc-footerLogo': 'var(--c-WHITE)',
            '--tc-footerLogoBrightness': '1000',
            '--tc-footerText': 'var(--c-WHITE)',
            '--tc-footerTextSecondary': 'var(--c-NUETRAL-6)'
        },
        brand: {
            '--tc-surface': 'var(--c-WHITE)',
            '--tc-surfaceAccent': 'var(--c-PRIMARY)',
            '--tc-surfaceSecondary': 'var(--c-NUETRAL-2)',
            '--tc-border': 'var(--c-NUETRAL-4)',
            '--tc-borderAccent': 'var(--c-PRIMARY)',
            '--tc-text': 'var(--c-NUETRAL-9)',
            '--tc-textAccent': 'var(--c-WHITE)',
            '--tc-link': 'var(--c-PRIMARY)',
            '--tc-linkFocus': 'var(--c-NUETRAL-9)',
            '--tc-linkAccent': 'var(--c-PRIMARY)',
            '--tc-linkAccentFocus': 'var(--c-NUETRAL-9)',
            '--tc-buttonSurface': 'var(--c-PRIMARY)',
            '--tc-buttonBorder': 'var(--c-PRIMARY)',
            '--tc-buttonText': 'var(--c-WHITE)',
            '--tc-buttonSurfaceAccent': 'var(--c-PRIMARY)',
            '--tc-buttonSurfaceAccentBorder': 'var(--c-WHITE)',
            '--tc-buttonSurfaceAccentText': 'var(--c-WHITE)',
            '--tc-bannerSurface': 'var(--c-PRIMARY)',
            '--tc-bannerText': 'var(--c-WHITE)',
            '--tc-headerSurface': 'var(--c-WHITE)',
            '--tc-headerBorderTop': 'var(--c-PRIMARY)',
            '--tc-headerBorderBottom': 'var(--c-NUETRAL-4)',
            '--tc-headerLogo': 'var(--c-PRIMARY)',
            '--tc-headerText': 'var(--c-NUETRAL-9)',
            '--tc-headerLink': 'var(--c-NUETRAL-9)',
            '--tc-headerLinkHover': 'var(--c-PRIMARY)',
            '--tc-headerInput': 'var(--c-NUETRAL-9)',
            '--tc-headerIcon': 'var(--c-NUETRAL-9)',
            '--tc-footerSurface': 'var(--c-PRIMARY)',
            '--tc-footerLogo': 'var(--c-WHITE)',
            '--tc-footerLogoBrightness': '1000',
            '--tc-footerText': 'var(--c-WHITE)',
            '--tc-footerTextSecondary': 'var(--c-WHITE)'
        },
        brandContrast: {
            '--tc-surface': 'var(--c-WHITE)',
            '--tc-surfaceAccent': 'var(--c-PRIMARY)',
            '--tc-surfaceSecondary': 'var(--c-NUETRAL-2)',
            '--tc-border': 'var(--c-NUETRAL-4)',
            '--tc-borderAccent': 'var(--c-PRIMARY)',
            '--tc-text': 'var(--c-NUETRAL-9)',
            '--tc-textAccent': 'var(--c-NUETRAL-9)',
            '--tc-link': 'var(--c-BLACK)',
            '--tc-linkFocus': 'var(--c-BLACK)',
            '--tc-linkAccent': 'var(--c-BLACK)',
            '--tc-linkAccentFocus': 'var(--c-BLACK)',
            '--tc-buttonSurface': 'var(--c-PRIMARY)',
            '--tc-buttonBorder': 'var(--c-PRIMARY)',
            '--tc-buttonText': 'var(--c-NUETRAL-9)',
            '--tc-buttonSurfaceAccent': 'var(--c-PRIMARY)',
            '--tc-buttonSurfaceAccentBorder': 'var(--c-NUETRAL-9)',
            '--tc-buttonSurfaceAccentText': 'var(--c-NUETRAL-9)',
            '--tc-bannerSurface': 'var(--c-PRIMARY)',
            '--tc-bannerText': 'var(--c-NUETRAL-9)',
            '--tc-headerSurface': 'var(--c-WHITE)',
            '--tc-headerBorderTop': 'var(--c-PRIMARY)',
            '--tc-headerBorderBottom': 'var(--c-NUETRAL-4)',
            '--tc-headerLogo': 'var(--c-PRIMARY)',
            '--tc-headerText': 'var(--c-NUETRAL-9)',
            '--tc-headerLink': 'var(--c-NUETRAL-9)',
            '--tc-headerLinkHover': 'var(--c-PRIMARY)',
            '--tc-headerInput': 'var(--c-NUETRAL-9)',
            '--tc-headerIcon': 'var(--c-NUETRAL-9)',
            '--tc-footerSurface': 'var(--c-PRIMARY)',
            '--tc-footerLogo': 'var(--c-NUETRAL-9)',
            '--tc-footerLogoBrightness': '1000',
            '--tc-footerText': 'var(--c-NUETRAL-9)',
            '--tc-footerTextSecondary': 'var(--c-NUETRAL-9);'
        },
    }
    const headingSizes = {
        large: {
            '--t-HEADING-MARQUEE-MOBILE': '700 18vw/1.1 var(--f-PRIMARY)',
            '--t-HEADING-MARQUEE': '700 100px/1.1 var(--f-PRIMARY)',
            '--t-HEADING-1': '700 80px/1.1 var(--f-PRIMARY)',
            '--t-HEADING-2': '700 60px/1.1 var(--f-PRIMARY)',
        },medium: {
            '--t-HEADING-MARQUEE-MOBILE': '700 14vw/1.1 var(--f-PRIMARY)',
            '--t-HEADING-MARQUEE': '700 80px/1.1 var(--f-PRIMARY)',
            '--t-HEADING-1': '700 70px/1.1 var(--f-PRIMARY)',
            '--t-HEADING-2': '700 50px/1.1 var(--f-PRIMARY)',
        },
        small: {
            '--t-HEADING-MARQUEE-MOBILE': '700 10vw/1.1 var(--f-PRIMARY)',
            '--t-HEADING-MARQUEE': '700 70px/1.1 var(--f-PRIMARY)',
            '--t-HEADING-1': '700 60px/1.1 var(--f-PRIMARY)',
            '--t-HEADING-2': '700 45px/1.1 var(--f-PRIMARY)',
        }
    }
    const state = {
        isOpen: false,
        savedSettingsPersist: true,
        defaultBrandColor: window.sfsd.brandColor,
        defaultThemeStyleName: 'Light',
        defaultLogoSrc: document.querySelector('.globalHeader-logo img').attributes.src.value,
        defaultLogoName: 'Default',
        defaultFontStyle: 'default',
        defaultHeadingStyle: 'uppercase',
        defaultHeadingSize: 'large',
        stagedBrandColor: '',
        stagedThemeStyleName: '',
        stagedLogoSrc: '',
        stagedLogoName: '',
        stagedFontStyle: '',
        stagedHeadingStyle: '',
        stagedHeadingSize: '',
        setBrandColor: getComputedStyle(document.documentElement).getPropertyValue('--c-PRIMARY'),
        setThemeStyleName: '',
        setLogoSrc: document.querySelector('.globalHeader-logo img').attributes.src.value,
        setLogoName: '',
        setThemeStyle: {},
        setFontStyle: '',
        setHeadingStyle: '',
        setHeadingSize: '',
        savedBrandColor: sessionStorage.getItem('savedBrandColor'),
        savedThemeStyleName: sessionStorage.getItem('savedThemeStyleName'),
        savedLogoSrc: sessionStorage.getItem('savedLogoSrc'),
        savedLogoName: sessionStorage.getItem('savedLogoName'),
        savedFontStyle: sessionStorage.getItem('savedFontStyle'),
        savedHeadingStyle: sessionStorage.getItem('savedHeadingStyle'),
        savedHeadingSize: sessionStorage.getItem('savedHeadingSize'),
        fontsList: [
            {
                name: 'Sans Serif (default)',
                value: 'default',
            }, {
                name: 'Serif',
                value: 'Playfair Display',
            }, {
                name: 'Rounded',
                value: 'Nunito',
            }
        ],
    }

    init();

    function init () {
        document.body.append(styleWidgetEle);
        styleWidgetEle.addEventListener('click', handleClick);
        styleWidgetEle.addEventListener('input', handleInput);
        styleWidgetEle.addEventListener('change', handleChange);
        checkForSavedStyles();
        render();
    }

    function handleClick (e) {
        if (e.target.matches('.js--action-toggleStyleWidget')) {
            if (e.target.closest('.js--element-sWidget').classList.contains('isOpen')) {
                e.target.closest('.js--element-sWidget').classList.remove('isOpen');
                clearStagedStyles();
                render();
            } else {
                e.target.closest('.js--element-sWidget').classList.add('isOpen');
            }
            return;
        }
        if (e.target.matches('.js--element-themeStylePicker > *')) {
            e.target.parentElement.querySelectorAll('.isCurrent').forEach( item => item.classList.remove('isCurrent') );
            e.target.classList.add('isCurrent');
            udpateThemeStyle(e.target.dataset.themeStyleName);
            return;
        }
        if (e.target.matches('.js--action-saveStyles')) {
            e.preventDefault();
            if (state.stagedBrandColor) {
                state.setBrandColor = state.stagedBrandColor;
                sessionStorage.setItem('savedBrandColor', state.stagedBrandColor);
            }
            if (state.stagedThemeStyleName) {
                state.setThemeStyleName = state.stagedThemeStyleName;
                sessionStorage.setItem('savedThemeStyleName', state.stagedThemeStyleName);
            }
            if (state.stagedLogoSrc) {
                state.setLogoSrc = state.stagedLogoSrc;
                state.setLogoName = state.stagedLogoName;
                sessionStorage.setItem('savedLogoSrc', state.stagedLogoSrc);
                sessionStorage.setItem('savedLogoName', state.stagedLogoName);
                sessionStorage.removeItem('stagedLogoSrc');
            }
            if (state.stagedFontStyle) {
                state.setFontStyle = state.stagedFontStyle;
                sessionStorage.setItem('savedFontStyle', state.stagedFontStyle);
            }
            if (state.stagedHeadingStyle) {
                state.setHeadingStyle = state.stagedHeadingStyle;
                sessionStorage.setItem('savedHeadingStyle', state.stagedHeadingStyle);
            }
            if (state.stagedHeadingSize) {
                state.setHeadingSize = state.stagedHeadingSize;
                sessionStorage.setItem('savedHeadingSize', state.stagedHeadingSize);
            }
            render();
        }
        if (e.target.matches('.js--action-resetDefaults')) {
            sessionStorage.removeItem('savedBrandColor');
            sessionStorage.removeItem('savedThemeStyleName');
            sessionStorage.removeItem('savedLogoSrc');
            sessionStorage.removeItem('savedLogoName');
            sessionStorage.removeItem('savedFontStyle');
            sessionStorage.removeItem('savedHeadingStyle');
            sessionStorage.removeItem('savedHeadingSize');
            state.setBrandColor = state.defaultBrandColor;
            state.setThemeStyleName = state.defaultThemeStyleName;
            state.setLogoSrc = state.defaultLogoSrc;
            state.setLogoName = state.defaultLogoName;
            state.setFontStyle = state.defaultFontStyle;
            state.setHeadingStyle = state.defaultHeadingStyle;
            state.setHeadingSize = state.defaultHeadingSize;
            updateBrandColor(state.setBrandColor)
            udpateThemeStyle(state.setThemeStyleName);
            updateLogos(state.setLogoSrc);
            udpateFonts(state.setFontStyle);
            updateHeadingStyle(state.setHeadingStyle);
            udpateHeadingSize(state.setHeadingSize);
            render();
        }
        if (e.target.matches('.js--element-persistToggle')) {
            if (state.savedSettingsPersist = true) {
                sessionStorage.removeItem('savedSettingsPersist');
                state.savedSettingsPersist = false;
            } else {
                sessionStorage.setItem('savedSettingsPersist', true);
                state.savedSettingsPersist = true;
            }
        }
    }

    function handleInput (e) {
        if (e.target.matches('.js--element-brandColorPicker')) {
            state.stagedBrandColor = e.target.value;
            updateBrandColor(e.target.value);
            return;
        }
    }

    function handleChange (e) {
        if (e.target.matches('.js--element-fontStyleSelect')) {
            state.stagedFontStyle = e.target.options[e.target.selectedIndex].value;
            udpateFonts(state.stagedFontStyle);
            return;
        }
        if (e.target.matches('.js--element-logoFileInput')) {
            const reader = new FileReader();
    
            reader.addEventListener('load', () => {
                sessionStorage.setItem('stagedLogoSrc', reader.result);
                state.stagedLogoSrc = sessionStorage.getItem('stagedLogoSrc');
                updateLogos(state.stagedLogoSrc);
            });
            reader.readAsDataURL(e.target.files[0]);
            state.stagedLogoName = e.target.files[0].name;
        }
        if (e.target.matches('.js--element-hdgStyleSelect')) {
            state.stagedHeadingStyle = e.target.options[e.target.selectedIndex].value;
            updateHeadingStyle(state.stagedHeadingStyle);
        }
        if (e.target.matches('.js--element-headingSizeSelect')) {
            state.stagedHeadingSize = e.target.options[e.target.selectedIndex].value;
            udpateHeadingSize(state.stagedHeadingSize);
        }
    }

    function updateLogos (srcTarget) {
        document.querySelector('.globalHeader-logo img').setAttribute('src', srcTarget);
        document.querySelector('.globalFooter-logo > img').setAttribute('src', srcTarget);
    }

    function udpateFonts (updatedFontStyle) {
        state.stagedFontStyle = updatedFontStyle;

        if (state.stagedFontStyle === 'default') {
            fontStyleEle.innerHTML = '';
            return;
        }

        customFontLink.rel = "stylesheet"
        customFontLink.href = `https://fonts.googleapis.com/css2?family=${state.stagedFontStyle.replaceAll(' ', '+')}&display=swap`;
        document.head.appendChild(customFontLink);
        updateFontStack();
    }

    function updateFontStack () {
        const newFontStack = `'${state.stagedFontStyle.replaceAll('+', ' ')}', Arial, Helvetica Neue, Helvetica, sans-serif`;

        fontStyleEle.innerHTML = `
            :root {
                --f-PRIMARY: ${newFontStack};
                --f-SECONDARY: ${newFontStack};
                --f-TERTIARY: ${newFontStack};
            }
        `;
        document.head.appendChild(fontStyleEle);
    }

    function updateHeadingStyle (headingStyle) {
        document.documentElement.style.setProperty('--t-TEXT-TRANSFORM', headingStyle);
    }

    function udpateHeadingSize (headingSize) {
        state.stagedHeadingSize = headingSize;
        let headingSizesObject = {};

        switch (headingSize) {
            case 'large':
                headingSizesObject = headingSizes.large;
                break;
            case 'medium':
                headingSizesObject = headingSizes.medium;
                break;
            case 'small':
                headingSizesObject = headingSizes.small;
                break;
            default:
                headingSizesObject = headingSizes.large;
        }
        setVarProperties(headingSizesObject);
    }

    function clearStagedStyles () {
        document.documentElement.style = '';
        state.setLogoName = '';
        updateBrandColor(state.setBrandColor);
        udpateThemeStyle(state.setThemeStyleName);
        updateLogos(state.setLogoSrc);
        udpateFonts(state.setFontStyle);
        updateHeadingStyle(state.setHeadingStyle);
        udpateHeadingSize(state.setHeadingSize);
    }

    function checkForSavedStyles () {
        if (state.savedSettingsPersist = true) {
            sessionStorage.setItem('savedSettingsPersist', true);
        }
        if (state.savedBrandColor) {
            state.setBrandColor = state.savedBrandColor;
            updateBrandColor(state.savedBrandColor);
        }
        if (state.savedThemeStyleName) {
            state.setThemeStyleName = state.savedThemeStyleName;
            udpateThemeStyle(state.savedThemeStyleName);
        } else {
            state.setThemeStyleName = state.defaultThemeStyleName;
        }
        if (state.savedLogoSrc) {
            state.setLogoSrc = state.savedLogoSrc;
            state.setLogoName = state.savedLogoName;
            updateLogos(state.savedLogoSrc);
        } else {
            state.setLogoName = state.defaultLogoName;
        }
        if (state.savedFontStyle) {
            state.setFontStyle = state.savedFontStyle;
            udpateFonts(state.setFontStyle);
        } else {
            state.setFontStyle = state.defaultFontStyle;
        }
        if (state.savedHeadingStyle) {
            state.setHeadingStyle = state.savedHeadingStyle;
            updateHeadingStyle(state.setHeadingStyle);
        } else {
            state.setHeadingStyle = state.defaultHeadingStyle;
        }
        if (state.savedHeadingSize) {
            state.setHeadingSize = state.savedHeadingSize;
            udpateHeadingSize(state.setHeadingSize);
        }
    }

    function udpateThemeStyle (themeStyleName) {
        state.stagedThemeStyleName = themeStyleName;

        switch (themeStyleName) {
            case 'Light':
                state.setThemeStyle = themeStyles.light;
                break;
            case 'Dark':
                state.setThemeStyle = themeStyles.dark;
                break;
            case 'Brand':
                state.setThemeStyle = themeStyles.brand;
                break;
            case 'Brand Contrast':
                state.setThemeStyle = themeStyles.brandContrast;
                break;
            default:
                state.setThemeStyle = themeStyles.light;
        }
        setVarProperties(state.setThemeStyle);
    }

    function setVarProperties (styleObject) {
        for (const [key, value] of Object.entries(styleObject)) {
            document.documentElement.style.setProperty(`${key}`, `${value}`);
        }
    }

    function updateBrandColor (brandColor) {
        document.documentElement.style.setProperty('--c-PRIMARY', brandColor);
    }

    function render () {
        styleWidgetEle.innerHTML = styleWidgetHtml(state);
    }
})();